<template>
    <div id="category">
        <div class="category-header">
            <searchV1
                    class="search"
                    v-model="keyword"
                    @search="search"
                    @clear="search"
            ></searchV1>

        </div>
        <div class="l-flex-g1 box-p-15" v-if="goodsList.length > 0">
            <scrollview @onRefresh="onRefresh" @onReload="onReload" :height="scrollHeight">
                <gridV2>
                    <goodsV1
                            v-for="(item, index) in goodsList"
                            :key="index"
                            :url="item.cover"
                            :title="item.title"
                            :product_price="item.product_price"
                            :market_price="item.market_price"
                            :stock_number="item.stock_number"
                            :mail_type="item.mail_type"
                            :param="item"
                            @onClick="goDetail"
                            class="box-m-b-10"
                    ></goodsV1>
                </gridV2>
            </scrollview>
        </div>
    </div>
</template>
<script>
  import searchV1 from '@T/community/search/searchV1.vue'
  import goodsV1 from '@T/community/goods/goodsV1.vue'
  import menuV3 from '@T/community/menu/menuV3.vue'
  import gridV2 from '@/uicomponent/grid/gridV2.vue'
  import cellV1 from '@T/community/cell/cellV1.vue'

  export default {
    components: {
      searchV1,
      menuV3,
      goodsV1,
      gridV2,
        cellV1
    },
    data () {
      return {
        current: 0,
        keyword: '',
        params: {
          page: 1,
          page_size: 10,
        },
        menuList: [],
        goodsList: [],
        category_type:1, //分类id 1是社区团购页面    2是产地直销页面  3今日团购
        scrollHeight:0
      }
    },
    mounted(){
      //计算容器的高度
      this.scrollHeight = window.innerHeight - document.querySelector(".category-header").clientHeight - 30
    },
    async created () {

      if (this.$route.query) {
        this.current = Number(this.$route.query.index) || 0
      }
      if(this.$route.query.category_type){
          this.category_type=this.$route.query.category_type
      }
      if (this.category_type==1){
            document.title = '社区团购'
      }else if(this.category_type==2){
            document.title = '产地直销'
      }else{
            document.title = '今日团购'
      }
      //await this.getMenuList()
      await this.getGoodsList()
    },
    methods: {
      onRefresh (done) {
        this.goodsList = []
        this.params.page = 1
        this.getGoodsList().finally(() => {
          done()
        })
      },
      /**
       * step 当前加载结束
       * over 没有更多数据了
       */
      onReload (step, over) {
        this.params.page += 1
        this.getGoodsList().finally(() => {
          if (this.goodsList.length < this.params.page * this.params.page_size) {
            over()
          } else {
            step()
          }
        })
      },
      // async getMenuList () {
      //   try {
      //     let query = await this.$api.community.category.getTypeList()
      //     this.menuList = query.data
      //   } catch (error) {
      //     // console.log("menuList error", error);
      //   }
      // },
      async getGoodsList () {
        let params = {

          ...this.params,
          keyword: this.keyword,
          category_type:this.category_type
        }
        try {
          if (this.category_type==2){ //产地直销

              let query = await this.$api.community.category.OriginDirectSelling(params)
              if (query.data.length == 0) {
                  return
              }
              this.goodsList = [...this.goodsList, ...query.data]
              console.log('商品列表',this.goodsList)

          }else{ //1 社区团购  3 是今日团购
              //params.push({category_type:this.category_type})
              let query = await this.$api.community.home.groupBuyingToday(params)
              if (query.data.length == 0) {
                  return
              }
              this.goodsList = [...this.goodsList, ...query.data]
              console.log('商品列表',this.goodsList)

          }


        } catch (error) {
          //
        }
      },
      search () {
        this.goodsList = []
        this.params = {
          page: 1,
          page_size: 10,
        }
        this.keyword = this.$utils.string.trim(this.keyword)
        this.getGoodsList()
      },
      goDetail (e) {
        let goodsId = e.id
        if (this.$platform.wxsdk.isWechat()) {
          let query = {
            goodsId: goodsId,
          }
          query = encodeURIComponent(JSON.stringify(query))
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/community/goods-detail?query=' + query,
          })
        } else {
          this.$router.push({
            path: 'goods-detail',
            query: {goodsId: goodsId},
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
    #category {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100%;
        background: #f6f6f6;

        .category-header{
            padding: 15px 15px 1px 15px;
            background: #f6f6f6;
        }
    }

    .search {
        width: 345px;
        height: 32px;
    }
</style>
