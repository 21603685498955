<template>
  <div class="menu">
    <flowV1>
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        :class="{ 'item-active': index == currentIndex }"
        @click="itemOnClick(item, index)"
      >
        {{ item[titleKey] }}
      </div>
    </flowV1>
  </div>
</template>
<script>
import flowV1 from "@/uicomponent/flow/flowV1.vue";
export default {
  components: {
    flowV1,
  },
  props: {
    titleKey: {
      type: String,
      default: "name",
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    value() {
      this.currentIndex = this.$props.value;
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  created() {
    this.currentIndex = this.$props.value;
  },
  methods: {
    itemOnClick(item, index) {
      this.currentIndex = index;
      this.$emit("input", index);
      this.$emit("onChange");
    },
  },
};
</script>
<style lang="scss" scoped>
.menu {
  width: 100%;
  height: 50px;
  @include b(item) {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    margin-right: 18px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #939393;
    @include m(active) {
      font-size: 14px;
      font-weight: 600;
      color: #333333;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -6px;
        width: 100%;
        height: 6px;
        background: linear-gradient(135deg, #ffb56d 0%, #ff8116 100%);
      }
    }
  }
}
</style>
