<template>
  <uiCellV1
    :leftClass="['left']"
    :leftIcon="leftIcon"
    :leftIconClass="['left_img']"
    :leftText="leftText"
    :leftTextClass="['left_text']"
    :rightClass="['right']"
    :rightIcon="rightIcon"
    :rightIconClass="['right_img']"
    :rightText="rightText"
    :rightTextClass="['right_text']"
    @onRight="onRightClick"
    @onLeft="onLeftClick"
  >
  </uiCellV1>
</template>

<script>
import uiCellV1 from "@/uicomponent/cell/cellV1.vue";
export default {
  components: {
    uiCellV1,
  },
  props: {
    leftIcon: {
      type: String,
      default: require("@/static/image/icons/hotGoods.png"),
    },
    leftText: {
      type: String,
      default: "查看更多",
    },
    rightIcon: {
      type: String,
      default: require("@/static/image/icons/arrowRight.png"),
    },
    rightText: {
      type: String,
      default: "查看全部",
    },
  },
  methods: {
    onLeftClick() {
      this.$emit("onLeft");
    },
    onRightClick() {
      this.$emit("onRight");
    },
  },
};
</script>

<style lang="scss" scope>
@include b(left) {
  align-items: center;
  @include e(img) {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    background: #333333;
  }
  @include e(text) {
    margin-left: 3px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
  }
}
@include b(right) {
  align-items: center;
  @include e(img) {
    flex-shrink: 0;
    width: 12px;
    height: 12px;
  }
  @include e(text) {
    margin-right: 1px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #939393;
  }
}
</style>
